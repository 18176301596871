import React, { useState, useEffect } from 'react';
import OrderForm from './components/OrderForm';
import LazyImage from './components/LazyImage';


// Preload image function
const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = resolve;
    img.onerror = reject;
  });
};

const App = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  // List of images to preload
  const imagePaths = ['header.jpg', 'fridge.jpg', 'market.jpg'];

  useEffect(() => {
    const preloadImages = async () => {
      try {
        await Promise.all(imagePaths.map(preloadImage));
        setImagesLoaded(true); // Set images as loaded once all are preloaded
      } catch (error) {
        console.error('Image preloading failed', error);
      }
    };

    preloadImages();
  }, []);

  if (!imagesLoaded) {
    return <div style={styles.loading}>Laadimine...</div>; // Loading state while images are preloaded
  }

  return (
    <div style={styles.container}>
      {/* Header */}
      <header style={styles.header}>
        <img src="header.jpg" alt="Market" style={styles.headerImage} />
      </header>

      {/* Images Section */}

      <section style={styles.imageSection}>
        <LazyImage src="fridge.jpg" alt="Fridge" style={styles.image} />
        <LazyImage src="market.jpg" alt="Market" style={styles.image} />
      </section>


      {/* Intro Section */}
      <section style={styles.introSection}>
        <h2 style={styles.introTitle}>Meie lugu</h2>
        <p style={styles.introText}>
          Valmistame kodukalja, mille retsept ja valmistamisviis on inspireeritud pikaajalistest traditsioonidest.
          2024. aasta suvel müüsime oma kodukalja Viimsi turul, kus see leidis sooja vastuvõtu ja tekitas huvi
          kohalike seas. Meie õpetaja, kellel on selles valdkonnas rohkelt kogemusi, on Tatarstanis kalja
          valmistanud alates 2015. aastast. Ta on loonud kaljatootmisega tegeleva eduka ettevõtte, jagades oma
          teadmisi ja inspireerides meid selle traditsioonilise joogi loomisel ja turustamisel.
        </p>
      </section>

      {/* Modal */}
      {isModalVisible && (
        <div style={styles.modalOverlay}>
          <div style={styles.modal}>
            <h2 style={styles.modalTitle}>Teie tellimus on esitatud!</h2>
            <p style={styles.modalText}>Täname esitamise eest. Võtame teiega varsti ühendust.</p>
            <button
              style={styles.closeButton}
              onClick={() => setModalVisible(false)}
            >
              Sulge
            </button>
          </div>
        </div>
      )}

      {/* Order Form Section */}
      <main style={styles.main}>
        <h2>Esita tellimus</h2>
        <OrderForm setModalVisible={setModalVisible} />
      </main>

      {/* Footer */}
      <footer style={styles.footer}>
        <p>
          Võtta ühendust meili teel: <a href="mailto:telli@kvass.ee" style={styles.email}>telli@kvass.ee</a>
        </p>
      </footer>
    </div>
  );
};

const styles = {
  // Loading state styles
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    fontSize: '1.5rem',
    color: '#555',
    fontFamily: '"Poppins", Arial, sans-serif',
  },

  // Existing styles remain unchanged
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
    fontFamily: '"Poppins", Arial, sans-serif',
    backgroundColor: '#fff',
    textAlign: 'center',
  },
  header: {
    position: 'relative',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
  },
  headerImage: {
    maxWidth: '500px',
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
  imageSection: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    margin: '1rem 0',
    //width: '100%',
    padding: '20px',
    flexWrap: 'wrap', // Allow wrapping of elements if necessary,
  },
  image: {
    width: '100%',
    maxWidth: '420px',
    height: '500px',
    objectFit: 'cover',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '10px',
  },
  introSection: {
    padding: '2rem',
    backgroundColor: '#fff',
    textAlign: 'center',
  },
  introTitle: {
    fontSize: '2rem',
    marginBottom: '1rem',
    fontWeight: 'bold',
  },
  introText: {
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#555',
    maxWidth: '800px',
    margin: '0 auto',
  },
  main: {
    flex: 1,
    padding: '2rem',
  },
  footer: {
    padding: '1rem',
    backgroundColor: '#A6632E',
    color: '#fff',
    fontSize: '1rem',
  },
  email: {
    color: '#FFD700',
    textDecoration: 'none',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    backgroundColor: '#fefefe',
    border: '2px solid #C87F38',
    borderRadius: '8px',
    padding: '2rem',
    textAlign: 'center',
    maxWidth: '400px',
    width: '90%',
  },
  modalTitle: {
    fontSize: '1.8rem',
    marginBottom: '1rem',
    color: '#333',
  },
  modalText: {
    fontSize: '1rem',
    color: '#555',
    marginBottom: '1.5rem',
  },
  closeButton: {
    backgroundColor: '#C87F38',
    color: '#fff',
    padding: '0.75rem 1.5rem',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '1rem',
  },
};

export default App;
