import React, { useState } from 'react';

const LazyImage = ({ src, alt, style }) => {
    const [loaded, setLoaded] = useState(false);

    return (
        <div style={{ ...style, position: 'relative' }}>
            {!loaded && <div style={styles.placeholder}>Laadimine...</div>}
            <img
                src={src}
                alt={alt}
                style={{
                    ...style,
                    opacity: loaded ? 1 : 0,
                    transition: 'opacity 0.5s ease',
                }}
                onLoad={() => setLoaded(true)}
            />
        </div>
    );
};

const styles = {
    placeholder: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#f0f0f0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#999',
        fontSize: '1.2rem',
    },
};

export default LazyImage;
