import React, { useState } from 'react';
import { db } from '../firebaseConfig'; // Adjust the path if needed
import { collection, addDoc } from 'firebase/firestore';

const styles = {
    form: {
        maxWidth: '800px',
        margin: '0 auto',
        backgroundColor: '#fff',
        padding: '2rem',
        borderRadius: '8px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
    label: {
        display: 'block',
        marginBottom: '1rem',
        textAlign: 'left',
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
        padding: '0.75rem 0.75rem', // Apply padding for top/bottom and left/right
        fontSize: '1rem',
        marginTop: '0.5rem',
        borderRadius: '4px',
        border: '1px solid #ddd',
        boxSizing: 'border-box', // Ensures padding and border are included in the width
    },
    error: {
        color: 'red',
        fontSize: '0.9rem',
        marginTop: '0.25rem',
    },
    button: {
        width: '100%',
        padding: '0.75rem',
        fontSize: '1rem',
        backgroundColor: '#C87F38',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: '1rem',
        transition: 'background-color 0.3s',
    },
    buttonDisabled: {
        backgroundColor: '#d3d3d3',
        color: '#808080',
        cursor: 'not-allowed',
    },
};

const OrderForm = ({ setModalVisible }) => {
    const [formData, setFormData] = useState({
        name: '',
        amount: '',
        email: '',
        phone: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setErrors({ ...errors, [name]: '' });

        // Validate numeric fields
        if (name === 'amount') {
            if (value === '' || (/^\d+$/.test(value) && value >= 1 && value <= 30)) {
                setFormData({ ...formData, [name]: value });
            }
        } else if (name === 'phone') {
            if (value === '' || /^\d*$/.test(value)) {
                setFormData({ ...formData, [name]: value });
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name.trim()) newErrors.name = 'Nimi on kohustuslik.';
        if (!formData.amount.trim()) newErrors.amount = 'Kogus on kohustuslik.';
        if (!formData.email.trim()) newErrors.email = 'Email on kohustuslik.';
        if (!formData.phone.trim()) newErrors.phone = 'Telefoni number on kohustuslik.';

        // Validate email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (formData.email && !emailRegex.test(formData.email)) {
            newErrors.email = 'Sisestage korrektne emaili aadress.';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const isFormValid = () => {
        return (
            formData.name.trim() &&
            formData.amount.trim() &&
            formData.email.trim() &&
            formData.phone.trim() &&
            /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            await addDoc(collection(db, 'form-submissions'), {
                name: formData.name,
                amount: formData.amount,
                email: formData.email,
                phone: formData.phone,
                timestamp: new Date(),
            });

            setModalVisible(true);
            setFormData({
                name: '',
                amount: '',
                email: '',
                phone: '',
            });
        } catch (error) {
            console.error('Error submitting form to Firestore:', error.message);
            alert(`Failed to submit form. Error: ${error.message}`);
        }
    };

    return (
        <form onSubmit={handleSubmit} style={styles.form} noValidate>
            <label style={styles.label}>
                Nimi:
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    style={styles.input}
                />
                {errors.name && <span style={styles.error}>{errors.name}</span>}
            </label>
            <label style={styles.label}>
                Kogus liitrites:
                <input
                    type="text"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                    style={styles.input}
                />
                {errors.amount && <span style={styles.error}>{errors.amount}</span>}
            </label>
            <label style={styles.label}>
                Email:
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    style={styles.input}
                />
                {errors.email && <span style={styles.error}>{errors.email}</span>}
            </label>
            <label style={styles.label}>
                Telefoni number:
                <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    style={styles.input}
                />
                {errors.phone && <span style={styles.error}>{errors.phone}</span>}
            </label>
            <button
                type="submit"
                style={{
                    ...styles.button,
                    ...(isFormValid() ? {} : styles.buttonDisabled),
                }}
                disabled={!isFormValid()}
            >
                Saada
            </button>
        </form>
    );
};

export default OrderForm;
