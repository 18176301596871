// Import the Firebase SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // Import Firestore
//import { getAnalytics } from "firebase/analytics";

// Your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAyfF9egUv2TusGqM5QQpXTjolZvyrP-qs",
    authDomain: "kvass-ba865.firebaseapp.com",
    projectId: "kvass-ba865",
    storageBucket: "kvass-ba865.appspot.com", // Corrected storage URL
    messagingSenderId: "32992550362",
    appId: "1:32992550362:web:7c1212e8930e6f347474e3",
    measurementId: "G-S19B155MBQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);

// Initialize Analytics (if needed)
//const analytics = getAnalytics(app);
